// Here you can add other styles
.card,
.dropdown-menu,
.footer,
.header,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

.sidebar-overlaid {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px,
    0 5px 6px -3px rgb(0 0 0 / 25%);
}

.card-header {
  border-bottom: 1px solid var(--cui-card-border-color);
}

.footer,
.header {
  border: 0;
}

.sidebar-brand {
  justify-content: space-between;
  padding: 0 ($sidebar-nav-link-padding-x * 0.5) 0 ($sidebar-nav-link-padding-x * 0.75);
}

.sidebar-toggler {
  flex: 0 0 2rem;
  background-color: transparent;

  &::before {
    width: 2rem;
  }

  &:hover {
    background-color: transparent;
  }
}

.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-brand {
    justify-content: center;
    padding: 0;
  }
  .sidebar-toggler {
    display: none;
  }
}

// Here you can add other styles
@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 100;
  src: url('./NotoSansKR-Thin.otf') format('woff2'), url('./NotoSansKR-Thin.otf') format('woff'),
    url('./NotoSansKR-Thin.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 300;
  src: url('./NotoSansKR-Light.otf') format('woff2'), url('./NotoSansKR-Light.otf') format('woff'),
    url('./NotoSansKR-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 400;
  src: url('./NotoSansKR-Regular.otf') format('woff2'),
    url('./NotoSansKR-Regular.otf') format('woff'),
    url('./NotoSansKR-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 500;
  src: url('./NotoSansKR-Medium.otf') format('woff2'), url('./NotoSansKR-Medium.otf') format('woff'),
    url('./NotoSansKR-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 700;
  src: url('./NotoSansKR-Bold.otf') format('woff2'), url('./NotoSansKR-Bold.otf') format('woff'),
    url('./NotoSansKR-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 900;
  src: url('./NotoSansKR-Black.otf') format('woff2'), url('./NotoSansKR-Black.otf') format('woff'),
    url('./NotoSansKR-Black.otf') format('truetype');
}

* {
  box-sizing: border-box;
  /* font-family: 'Noto Sans KR', sans-serif; */
  font-family: 'Noto Sans KR';
}

// 2023-04-19 의료진웹 개선사항으로 추가
.date-picker .picker-input-group .form-control {
  text-align: center;
}
// 2023-08-09 테마색상 변경대응 글자 색을 검은색으로
.sidebar-nav .nav-item .nav-link {
  color: black !important;
}

.sidebar-nav .nav-item .nav-link .nav-icon {
  color: black;
}

// 2023.12.27 추가, Juhee
// ---- 환자 개인정보 -----
.cs-row {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  border-right: 1px solid #c0c0c0;
}

.cs-row > div {
  margin: 0;
  padding: 10px 20px;
  height: 60px;
  border-left: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  line-height: 40px;
}

.cs-row > div.cs-h100 {
  margin: 0;
  padding: 10px 10px;
  height: 100px;
  border-left: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  line-height: 40px;
}

.cs-row:first-child {
  border-top: 1px solid #c0c0c0 !important;
}

.cs-row > div:nth-child(odd) {
  background: #cbeafc;
}

.cm-imp {
  position: absolute;
  top: 13px;
  left: 9px;
  font-size: 1.2rem !important;
  font-weight: 800;
  color: red;
}

.cs-theader {
  width: 25%;
  text-align: center;
}

.cs-theader2 {
  width: 23%;
  text-align: center;
}

.cs-h5 {
  padding-bottom: 10px;
  border-bottom: 1px solid #c0c0c0;
}

.cs-b-rt {
  border-right: 1px solid #c0c0c0;
}

.cs-b-bm {
  border-bottom: 1px solid #c0c0c0;
}

// ---- 운동 처방전 ----
.cs-guid-btn {
  margin-left: 5px;
  font-size: 0.8rem;
  font-weight: 500;
}

.cs-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cs-input-s60 {
  width: 60px;
}

.cs-input-s100 {
  width: 100px;
}

.cs-input-s150 {
  width: 150px;
}

.cs-input-s200 {
  width: 200px;
}

// ---- 재활운동 경과 ----
.cs-card-title {
  padding: 4px 0 5px;
  font-size: 1.1rem;
  font-weight: 600 !important;
  color: #114a9f;
  text-align: center;
}

.cs-card-text {
  width: 100%;
  padding-top: 15px;
  font-size: 0.7rem;
  font-weight: 600 !important;
  color: #545454;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.cs-card-text2 {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.cs-card-text2,
.cs-card-text3 {
  width: 100%;
  font-size: 0.7rem;
  font-weight: 600 !important;
  color: #545454;
  text-align: center;
}

.cs-card-text3 {
  padding-top: 15px;
  line-height: 40px;
}

.cs-card-text-st {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #231f20;
}

.cs-card-text-st2 {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #231f20;
}

.cs-card-text-st60 {
  font-size: 3rem !important;
  color: #000000;
}

.cs-card-text-st40 {
  font-size: 3rem !important;
  color: #000000;
}
.cs-card-text-st10 {
  font-size: 1rem !important;
  color: #000000;
}
.cs-card-text-com {
  display: inline-block;
  width: 80px;
  height: 40px;
  padding-top: 5px;
  margin-top: 10px;
  margin-right: 10px;
  background-image: url('../assets/images/rectangle.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  color: #6d6e71;
  text-align: center;
}

.cs-img {
  width: 50px;
  height: 50px;
}

.cs-unit {
  font-size: 0.8rem !important;
  margin-top: 25px;
}

.cs-unit2 {
  font-size: 1rem !important;
  margin-top: 8px;
}

.cs-status {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  color: #6d6e71;
}

.cs-h-100 {
  height: 100px;
}

.cs-h-100 .cs-card-text {
  height: 65px;
  border-radius: 10px;
}

.cs-h-160 {
  height: 160px;
}

.cs-h-160 .cs-card-text {
  height: 100px;
  border-radius: 10px;
  line-height: 80px;
  text-align: right;
  padding-right: 20%;
}

.mt-3 {
  margin-bottom: 1rem !important;
}

.bg-hart,
.bg-step,
.bg-kcal {
  background-repeat: no-repeat;
  background-position: 20% 50%;
}

.bg-hart {
  background-image: url('../assets/images/hart.png');
  background-size: 34px 31px;
}

.bg-kcal {
  background-image: url('../assets/images/kcal.png');
  background-size: 31px 47px;
}

.size-full {
  width: 100%;
}

.btn-close {
  display: none;
}

.cs-chart-wrapper {
  position: relative;
  //padding-top: 50px;
  background-color: #f0f0f0;
  overflow: auto;
}

.cs-chart {
  padding-right: 15px;
}

.weekly {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 30px;
  width: 75.6px;
  height: 36px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #11499f;
  background-image: url('../assets/images/weekly_bg.png');
  background-size: cover;
  line-height: 2.6;
  text-align: center;
  z-index: 1000;
}

.cmd-p1 {
  margin-bottom: 20px;
}

.cmd-p1 p {
  margin: 0;
  padding: 0;
}

.cmd-p1 p.cmd-p1-title {
  margin-bottom: 8px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #000;
}

.cmd-p1-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.7rem;
  font-weight: 500;
  color: #888;
}

.cmd-p1-data > span > strong {
  font-size: 1.3rem;
  font-weight: 800;
  color: #11499f;
}

.cmd-p1-data > span > span {
  color: #11499f;
}

.cmd-title,
.cmd-title2,
.cmd-value,
.cmd-status {
  margin: 0;
  padding: 0;
  text-align: center;
}

.cmd-title,
.cmd-value,
.cmd-status {
  color: #11499f;
}

.cmd-title2,
.cmd-title3 {
  color: #000;
}

.cmd-title3 {
  margin: 0;
  padding: 10px 0 0;
  text-align: left;
}

.cmd-title,
.cmd-title2,
.cmd-title3 {
  font-size: 1rem;
  font-weight: 600;
}

.cmd-value {
  font-size: 1.8rem;
  font-weight: 800;
}

.cmd-status {
  font-size: 0.75rem;
  font-weight: 500;
}

.cmd-step-line {
  position: relative;
  display: inline-block;
  width: 270px;
  height: 35.5px;
  margin: 8px 0 4px;
  background-image: url('../assets/images/line_img.png');
  background-size: cover;
}

.cmd-step-line2 {
  position: relative;
  display: inline-block;
  width: 270px;
  height: 25px;
  margin: 40px 0 20px;
  background-image: url('../assets/images/line2_img.png');
  background-size: 270px 25px;
}

.cmd-section {
  position: absolute;
  display: inline-block;
  width: 81px;
  height: 23px;
  top: 1px;
  left: 57px;
  background-image: url('../assets/images/section.png');
  background-size: 81px 23px;
}

.cmd-user-min {
  position: absolute;
  display: inline-block;
  width: 75px;
  height: 37px;
  top: -40px;
  left: 0px;
  background-image: url('../assets/images/min.png');
  background-size: 75px 37px;
}

.cmd-user-max {
  position: absolute;
  display: inline-block;
  width: 75px;
  height: 37px;
  top: -40px;
  left: 115px;
  background-image: url('../assets/images/max.png');
  background-size: 75px 37px;
}

.cmd-min,
.cmd-max {
  position: absolute;
  display: inline-block;
  top: 30px;
  font-size: 0.5rem;
  color: #888;
}

.cmd-min {
  left: 0;
}

.cmd-max {
  right: 0;
}

.cmd-step {
  position: absolute;
  display: inline-block;
  width: 47px;
  height: 35.5px;
  top: 0;
  padding-top: 6px;
  border: 2px solid #11499f;
  font-size: 0.8rem;
  font-weight: 600;
  color: #11499f;
  text-align: center;
}

.step1 {
  left: 0;
}

.step2 {
  left: 46px;
}

.step3 {
  left: 89px;
}

.step4 {
  left: 131px;
}

.step5 {
  left: 177px;
}

.step6 {
  left: 223px;
}

//-- common --
.cs-name-size {
  width: 105px;
}

.cs-age-size {
  width: 56px;
}

.cs-date-size {
  width: 150px;
}

.cs-period-size {
  width: 320px;
}

.cs-phone-size {
  width: 140px;
}

.cs-text-size {
  width: 150px;
}
.cs-text2-size {
  width: 190px;
}
.cs-number-size {
  width: 80px;
}

.cs-number2-size {
  width: 165px;
}

.cs-select-size {
  width: 290px;
}

.cs-select-size2 {
  width: 150px;
}

.cs-spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
